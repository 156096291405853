import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

export default class Card extends React.Component {
  render() {
    const { product } = this.props
    const { title, variants, handle, images } = product
    const featuredimage = images && images.length ? images[0].localFile : null
    const maxPrice = Math.max.apply(null, variants.map((v) => {
        return v.price * 1
    }))
    const minPrice = Math.min.apply(null, variants.map((v) => {
        return v.price * 1
    }))
    const maxComparePrice = Math.max.apply(null, variants.filter((v) => v.compareAtPrice && v.compareAtPrice * 1 > v.price * 1).map((v) => {
        return v.compareAtPrice * 1
    }))
    return (
        <Link to={`/product/${handle}`} className="cProductCard" onFocus={this.props.onFocus || null}>
           {featuredimage && featuredimage.childImageSharp && featuredimage.childImageSharp.fluid ? 
            <Img
                fluid={featuredimage.childImageSharp.fluid}
                alt={title}
                className="cProductCard-image"
                />
           : null}
            <div className="cProductCard-content">
              <p className="cProductCard-title">{ title }</p>
              <p className="cProductCard-price">{maxPrice !== minPrice ? 'From ' : '' }${ minPrice.toFixed(2) } { maxComparePrice !== Infinity && maxComparePrice > 0 ? (<del>${ maxComparePrice.toFixed(2)}</del>) : null}</p>
            </div>
        </Link>
    )
  }
}
