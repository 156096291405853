import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from "../context/ThemeContext"
import Layout from '../components/Layout'
import ProductCard from '../components/Product/Card'

class ShopPageTemplate extends React.Component {
  componentDidMount() {
    this.context.hideNav()
  }

  render() {
    const { products, title } = this.props
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className="cShop cSplitScreen">
            <div className="cShop-left cSplitScreen-content">
              <div className="cSplitScreen-heading">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-title">Cleo's Books</p>
                <AniLink fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}  className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cSplitScreen-path">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-path-text"><AniLink fade duration={0.25} to="/menu">Menu</AniLink> / Shop</p>
                <AniLink fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}  className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cShop-column">
                <div className="cShop-titleBar">
                  <h2 className="cShop-title">{title}</h2>
                  <div className="cShop-cart">Cart: ${theme.checkout ? (theme.checkout.subtotalPriceV2.amount * 1).toFixed(2) : "0.00"}</div>
                </div>
              </div>
              <div className="cShop-row">
                  <div className="cShop-content">
                  {products.length <= 0 ? <p className="cShop-error">Sorry, there are no products available to purchase at the moment</p> : products.map((item, idx) => (<div className="cShop-card" key={`${item.id}-${idx}`}><ProductCard product={item} /></div>))}
                  </div>
                </div>
            </div>
            <AniLink className="cShop-right cSplitScreen-cover" fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25} >
              <p className="cSplitScreen-cover-title">Cleo's Books</p>
            </AniLink>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

ShopPageTemplate.contextType = ThemeContext

ShopPageTemplate.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      availableForSale: PropTypes.bool,
      handle: PropTypes.string,
      id: PropTypes.string,
      shopifyId: PropTypes.string,
      productType: PropTypes.string,
      title: PropTypes.string,
      vendor: PropTypes.string,
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.string,
          compareAtPrice: PropTypes.string
        })
      )
    })
  )
}

const ShopPage = ({ data }) => {
  const products = (data && data.shopifyCollection && data.shopifyCollection.products) || []
  return (
    <Layout>
      <ShopPageTemplate
        products={products}
        title="Book Lover Merchandise"
      />
    </Layout>
  )
}

ShopPage.propTypes = {
  data: PropTypes.shape({
    shopifyCollection: PropTypes.object,
  }),
}

export default ShopPage

export const pageQuery = graphql`
  query ShopPageTemplate {
    shopifyCollection(title: {eq: "Booklovers Shop"}) {
        handle
        id
        title
        shopifyId
        products {
          availableForSale
          handle
          id
          shopifyId
          productType
          title
          vendor
          images {
            localFile{
              childImageSharp {
                fluid(maxWidth: 385, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          variants {
            price
            compareAtPrice
          }
        }
      }
  }
`
